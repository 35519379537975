import { graphql } from "gatsby"
import TestImage from "components/test-image"
import Img from "gatsby-image"
import { Heading } from "theme-ui"
import { Button } from "components"
import { toast } from "components/toast"

type Props = {
  data: any
}

const Index = ({ data }: Props) => {
  const { edges: posts } = data.allMdx
  const { edges: images } = data.allImageSharp

  return (
    <>
      <Heading>Marozzo.com alpha-version</Heading>

      <div>
        {" "}
        <TestImage img={data.file.childImageSharp.fixed} />
      </div>
      {/* <div>
        {images?.map(({ node }: any, index: number) => (
          <Img key={index} fixed={node.fixed} />
          ))}
        </div> */}
      <p>Hello!</p>
      <p>
        This is an alpha version, please visit{" "}
        <a href="https://marozzo.com">marozzo.com</a> to access the live site.
        🙂
      </p>

      <Button onClick={() => toast(`🍞`, { persist: false })}>
        You can also have a toast!
      </Button>
      {/* <ul>
        {posts?.map(({ node }: any, index: number) => (
          <li key={index}>{node?.frontmatter?.title}</li>
          ))}
        </ul> */}
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query blogIndex {
    allMdx {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            author
          }
        }
      }
    }
    file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allImageSharp {
      edges {
        node {
          id
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  }
`
